import styled from "styled-components";
import { FaLinkedin } from "react-icons/fa";
import person from "../assets/img/person.png";
import CloudKing from "../assets/img/profile/Cloud-King.jpeg";
import Chase from "../assets/img/profile/Chase.png";
import Absent from "../assets/img/profile/Absent D.jpeg";
import Chase2 from "../assets/img/profile/Chase-2.jpeg";
import x from "../assets/img/X.png";

export default function Council() {
  const councilMembers = [
    {
      path: CloudKing,
      name: "Cloud King",
      title: "Founder & CEO",
      x: "https://x.com/cloudkingtv?s=21&t=vdss_7IFrqW0mDuOqpMvqQ",
    },
    {
      path: Absent,
      name: "Absent D",
      title: "Chief Innovation Officer",
      linkedin: "https://www.linkedin.com/in/dioni-vasquez/",
      x: "https://x.com/grindstonesaga?s=21&t=vdss_7IFrqW0mDuOqpMvqQ",
    },
    {
      path: Chase,
      name: "Chase",
      title: "Strategy & Vision",
      linkedin: "https://www.linkedin.com/in/kaizencore",
      x: "https://x.com/grindstonesaga?s=21&t=vdss_7IFrqW0mDuOqpMvqQ",
    },
    {
      path: Chase2,
      name: "Sol Homestead",
      title: "Head of Community",
      x: "https://x.com/grindstonesaga?s=21&t=vdss_7IFrqW0mDuOqpMvqQ",
    },
  ];

  return (
    <Container>
      <Title>The Council</Title>
      <CouncilGrid>
        {councilMembers.map((member, index) => (
          <Member key={index}>
            <MemberImage src={member.path} alt={member.name} />
            <MemberTitle>{member.title}</MemberTitle>
            <MemberName>{member.name}</MemberName>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "15px",
                marginTop: "auto",
              }}
            >
              {(member.linkedin || member.x) && (
                <>
                  {member.linkedin && (
                    <a
                      href={member.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedinIcon />
                    </a>
                  )}
                  {member.x && (
                    <a
                      href={member.x}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <XIcon src={x} alt="X icon" />
                    </a>
                  )}
                </>
              )}
            </div>
          </Member>
        ))}
      </CouncilGrid>
    </Container>
  );
}

const Container = styled.div`
  text-align: center;
  color: #fff;
  min-height: 450px;
  margin-bottom: 3rem;
`;

const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 5rem;
  text-transform: uppercase;
  color: white;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const CouncilGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10%;
`;

const Member = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 300px;

  a {
    margin-top: auto;
  }

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 250px;
    height: 200px;
  }
`;

const MemberImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

const MemberTitle = styled.h2`
  font-size: 1.5rem;
  margin: 5px 0;
  color: #fff;
`;

const MemberName = styled.h3`
  font-size: 1rem;
  color: #fff;
  opacity: 0.8;
  margin-bottom: 10px;
`;

const LinkedinIcon = styled(FaLinkedin)`
  font-size: 1.5rem;
  color: #fff;
  padding: 5px;
`;

const XIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  padding: 5px;
`;
