import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import MainPage from "./pages/MainPage";
import CreateDrop from "./pages/CreateDrop";

import "@fontsource/blinker/200.css";
import "@fontsource/blinker/300.css";

function App() {
  return (
    <Router>
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/create-drop" element={<CreateDrop />} />
      </Routes>
    </Router>
  );
}

export default App;
