import styled from "styled-components";
import logo from "../assets/img/logo.png";
import x from "../assets/img/X.png";
import discord from "../assets/img/discord.png";

const FooterDiv = styled.footer`
  padding: 2rem;
  margin-top: 3rem;
  border-top: 2px solid #1d2529;
  width: calc(100% - 4rem);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #a9a9a9;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const LogoImg = styled.img`
  width: 40px;
  height: 40px;

  @media (max-width: 420px) {
    width: 30px;
    height: 30px;
  }
`;

const ImageIcon = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: 10px;

  @media (max-width: 420px) {
    width: 15px;
    height: 15px;
  }
`;

const FooterText = styled.h3`
  @media (max-width: 990px) {
    font-size: 1rem;
  }

  @media (max-width: 420px) {
    font-size: 0.8rem;
  }
`;

export default function Footer() {
  return (
    <FooterDiv>
      <Logo>
        <LogoImg className="logo" src={logo} alt="Saga DAO logo" />
        <FooterText style={{ marginLeft: "5px", color: "white" }}>
          SAGA DAO
        </FooterText>
      </Logo>
      <FooterText>© Saga dao Ltd. 2024</FooterText>
      <Logo>
        <a
          href="https://discord.gg/sagadao"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ImageIcon src={discord} alt="Discord" />
        </a>
        <a
          href="https://x.com/SagaMobileDAO"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ImageIcon src={x} alt="Twitter" />
        </a>
      </Logo>
    </FooterDiv>
  );
}
