import "../App.css";
import CreateDropForm from "../components/create-drop-form";

export default function CreateDrop() {
  return (
    <div className="App">
      <CreateDropForm />
    </div>
  );
}
