import styled from "styled-components";
import { useState } from "react";
import { toast } from 'react-hot-toast';

const Container = styled.div`
  margin: 50px auto;
  width: 60%;
  padding: 40px;
  border-radius: 15px;
  background-color: #1d2529;
  color: white;

  h1 {
    font-weight: bold;
  }

  h2 {
    font-weight: bold;

    padding: 0px 40px;
  }
`;

const ViewDrop = styled.button`
  background-color: #9f2fd5; /* cor roxa do botão */
  color: #fff; /* cor do texto */
  padding: 10px 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: 0.15s;

  &:hover {
    background-color: #6e08aa; /* roxo mais escuro no hover */
  }
`;

const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const FormGroup = styled.div`
  background-color: transparent; /* fundo escuro */
  border: 2px solid #9f2fd5; /* borda roxa */
  border-radius: 10px;
  padding: 30px;
  width: 80%;
  margin: 20px auto;
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 10px;
  font-weight: bold;
`;

const Input = styled.input`
  margin-inline: auto;
  width: 95%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  border: none;
  font-size: 16px;
`;

const ChooseFileButton = styled.button`
  background-color: #9f2fd5; /* cor roxa */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: 0.15s;

  &:hover {
    background-color: #6e08aa; /* roxo mais escuro no hover */
  }
`;

// Hide the file input
const HiddenFileInput = styled.input`
  display: none;
`;

const NumberInput = styled(Input)`
  width: 95%;
  display: inline-block;
`;

const CheckboxList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #9f2fd5;
  border-radius: 5px;
  background-color: transparent;
`;

const CheckboxItem = styled.label`
  display: flex;
  align-items: center;
  padding: 5px 0;

  input {
    margin-right: 10px;
  }

  span {
    color: #fff;
  }
`;

const CreateDropButton = styled(ChooseFileButton)`
  width: 100%;
  margin-top: 20px;
`;

export default function CreateDropForm() {
  const [formData, setFormData] = useState({
    contractAddress: "",
    tokenSymbol: "",
    tokenDecimal: "",
    icon: null,
    tokensPerDrop: "",
    fcfsNumber: "",
    claimPerWallet: "",
    feePerWallet: "",
    chosenLists: [],
    listWallets: {},
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
  
    setFormData((prevState) => {
      const chosenLists = checked
        ? [...prevState.chosenLists, value]
        : prevState.chosenLists.filter((item) => item !== value);
  
      const listWallets = { ...prevState.listWallets };
      if (checked) {
        listWallets[value] = ""; // Inicializa com string vazia
      } else {
        delete listWallets[value]; // Remove a entrada se desmarcado
      }
  
      return {
        ...prevState,
        chosenLists,
        listWallets,
      };
    });
  };

  const handleListWalletsChange = (list, value) => {
    setFormData((prevState) => ({
      ...prevState,
      listWallets: {
        ...prevState.listWallets,
        [list]: value,
      },
    }));
  };

  // Function to handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the selected file

    // Update formData with the selected file
    setFormData((prevState) => ({
      ...prevState,
      icon: file, // Store the selected file in the "icon" field
    }));
  };

  // Function to trigger the hidden file input click
  const handleButtonClick = () => {
    document.getElementById("hiddenFileInput").click();
  };

  const handleCreateDrop = () => {
    console.log("handleCreateDrop")
    // Desestruturação dos dados do formulário
    const {
      contractAddress,
      tokenSymbol,
      tokenDecimal,
      icon,
      tokensPerDrop,
      fcfsNumber,
      claimPerWallet,
      feePerWallet,
      chosenLists,
      listWallets,
    } = formData;

    // Verificar se todos os campos estão preenchidos e se os campos numéricos são maiores que 0
    if (
      !contractAddress ||
      !tokenSymbol ||
      !icon ||
      !chosenLists.length ||
      !tokenDecimal ||
      tokenDecimal <= 0 || // Token Decimal deve ser maior que 0
      !tokensPerDrop ||
      tokensPerDrop <= 0 || // Tokens per drop deve ser maior que 0
      !fcfsNumber ||
      fcfsNumber <= 0 || // Number of FCFS deve ser maior que 0
      !claimPerWallet ||
      claimPerWallet <= 0 || // Claim per wallet deve ser maior que 0
      !feePerWallet ||
      feePerWallet <= 0 || // Fee per wallet deve ser maior que 0
      !listWallets ||
      Object.values(listWallets).some(value => value <= 0) // Number of wallets deve ser maior que 0
    ) {
      toast.error("Please fill out all fields correctly before creating the drop.", {
        duration: 2000 // 2000 milissegundos = 2 segundos
      });
    } else {
      // Imprimir os dados no console
      toast.success("Drop created successfully!", {
        duration: 2000 // 2000 milissegundos = 2 segundos
      });
      console.log(formData);
      setFormData({
        contractAddress: "",
        tokenSymbol: "",
        tokenDecimal: "",
        icon: null,
        tokensPerDrop: "",
        fcfsNumber: "",
        claimPerWallet: "",
        feePerWallet: "",
        chosenLists: [],
        listWallets: {},
      })
    }
  };

  return (
    <Container>
      <ContainerTitle>
        <h1>Create Drop</h1>
        <ViewDrop>View Drop</ViewDrop>
      </ContainerTitle>

      <h2>Token Details</h2>

      <FormGroup>
        <Label>
          Enter the token contract address for the drop you're creating
        </Label>
        <Input
          type="text"
          name="contractAddress"
          placeholder="Contract Address"
          value={formData.contractAddress}
          onChange={handleChange}
        />

        <Label>Token Symbol</Label>
        <Input
          type="text"
          name="tokenSymbol"
          placeholder="Token Symbol"
          value={formData.tokenSymbol}
          onChange={handleChange}
        />

        <Label>Token Decimals</Label>
        <Input
          type="number"
          name="tokenDecimal"
          placeholder="Token Decimal"
          value={formData.tokenDecimal}
          onChange={handleChange}
        />

        <Label>Icon</Label>
        <div>
          <ChooseFileButton type="button" onClick={handleButtonClick}>
            Choose File
          </ChooseFileButton>
          <HiddenFileInput
            id="hiddenFileInput"
            type="file"
            accept="image/*" // You can restrict the file type
            onChange={handleFileChange}
          />

          {/* Optionally, display the file name after selection */}
          {formData.icon && <p>Selected File: {formData.icon.name}</p>}
        </div>
      </FormGroup>

      <h2>Drop Details</h2>

      <FormGroup>
        <Label>Enter the total number of tokens for the drop</Label>
        <Input
          type="number"
          name="tokensPerDrop"
          placeholder="Number of tokens per drop"
          value={formData.tokensPerDrop}
          onChange={handleChange}
        />

        <Label>Enter the number of FCFS wallets which can claim</Label>
        <Input
          type="number"
          name="fcfsNumber"
          placeholder="Number of FCFS wallets"
          value={formData.fcfsNumber}
          onChange={handleChange}
        />

        <Label>Claim per Wallet</Label>
        <NumberInput
          type="number"
          name="claimPerWallet"
          placeholder="Claim per wallet"
          value={formData.claimPerWallet}
          onChange={handleChange}
        />

        <Label>Fee per Wallet (5% of claim)</Label>
        <NumberInput
          type="number"
          name="feePerWallet"
          placeholder="Fee per wallet"
          value={formData.feePerWallet}
          onChange={handleChange}
        />

        <Label>Choose List</Label>
        <CheckboxList>
        {["Saga Genesis", "Solana Mobile Ch 2", "WIF"].map((list) => (
          <div key={list}>
            <CheckboxItem>
              <input
                type="checkbox"
                name="chosenLists"
                value={list}
                checked={formData.chosenLists.includes(list)}
                onChange={handleCheckboxChange}
              />
              <span>{list}</span>
            </CheckboxItem>
            {formData.chosenLists.includes(list) && (
              <NumberInput
                type="number"
                placeholder={`Number of wallets for ${list}`}
                value={formData.listWallets[list]}
                onChange={(e) => handleListWalletsChange(list, e.target.value)}
              />
            )}
          </div>
        ))}
      </CheckboxList>

        <CreateDropButton type="button" onClick={handleCreateDrop}>
          Create a drop
        </CreateDropButton>
      </FormGroup>
    </Container>
  );
}
